.modalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}

.botox_color_box{
  padding-bottom: .05%;
  padding-top: .1%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notes_div {
  padding-bottom: .2%;
}

.notes_text_area {
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  max-height: 100px;
}

.enlighten_panel_button {
  margin-right: 1%;
}

.modal_header {
  padding: 0px 10px;
}

.top_row_dropdowns {
  display: flex;
  justify-content: space-evenly;
}


.add_notes_padding {
  padding-bottom: 1%;
}

.full_width_container {

    width: 100% !important;
    max-width: 100%;
}