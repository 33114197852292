.patientConsentUl {
    list-style-type: none;
    padding-top: 1.3em;
    padding-bottom: 1.3em;
}

.patientConsentUl > li {
    padding-bottom: 0.3em;
}

.patientConsentWrapper {
    /* background-color: red; */
    margin-left: auto;
    margin-right: auto;
    /* margin: auto; */
    width: 80%;
    /* height: 200px;
    border: 1px solid black; */
    /* height: 200px; */
}

.consentSignaturePad {
    width: 100%;
    height: 14em; /*200px;*/
    background-color: white;
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
    margin-top:0.7em;
    /* border: 2px solid var(--primary-color); */
    /* border-radius: 4px; */
}

.consentSignaturePadNormal {
    border-color: black;
}
.consentSignaturePadError {
    border-color: red;
}

.errorMsg {
    color:red;
    /* padding-left: 0em; */
}

