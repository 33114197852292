.visit_main_div {
  height: 100vh;
}
/* .visual_upload_img_div {
  padding-left: 1%;
} */
.row_padding_left {
  /* padding-left: .5%; */
  padding-left: .7%;
  /* padding-left: 5%; */
  border-bottom: 2px solid black;
  margin-bottom: .5%;
}

  /* This is to make the width of the image col smaller to bring the name div closer on a medium screen */
/* .row_padding_left .col-md-2 {
  width: 12%;
} */

/* .col_image_div {
  width: 7%
} */

.col_div_record {
  margin-right: 2%;
}

.visit_record_div {
  padding-bottom: 1%;
}

.visit_record_div h4 {
  margin: 0;
  line-height: 1.5;
  /* line-height: .1; */
  /* display: flex;
  align-items: baseline; */
  /* padding-left: 20px; */
  display: flex;
  align-items: baseline;
}

/* .visit_record_div .label {
  display: inline-block;
  width: 70px;
} */




/* .visit_record_div h4::after {
  content: "";
  flex-grow: 1;
} */

.visit_searchbar_div {
  padding-bottom: 1%;
}

.visit_buttons_outer_div {
  display: flex;
}

img {
    border: 1px solid #ddd; /* Gray border */
    border-radius: 4px;  /* Rounded border */
    padding: 5px; /* Some padding */
    width: 150px; /* Set a small width */
  }
  
  /* Add a hover effect (blue shadow) */
  img:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }

  .split-pane-style {
    display: flex;
    position: fixed;
    /* z-index: 0; */
    /* overflow: hidden; */
    width: 100%;
    height: 100%;
    bottom: 0px;
    left: 0px
  }

  .sash-module_sash__K-9lB:before {
    content: "";
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: background-color 0.1s ease-out;
    background: rgb(0 0 0 / 87%);
}

.top-pane {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.camera_icon {
  font-size: 20px;
}
.camera_icon_90 {
  font-size: 20px;
  transform: rotate(90deg);
}
.camera_icon_180 {
  font-size: 20px;
  transform: rotate(180deg);
}
.camera_icon_270 {
  font-size: 20px;
  transform: rotate(270deg);
}

.camera_icon_selected {
  border: 2px solid black;
}

.visit-lower-pane-button {
  /* margin-right: 10px; */
  margin-right: 1%;
}

/* .camera_icon.selected {
  border: 2px solid black;
} */

.visit_upload_image_div {
  height: 90px;
  width: 90px;
  overflow: hidden;
  /* border-color: blue; */
  border: 3px solid;
}

.active_status_green {
  border: 2px solid green;
}

.visit_upload_image_div img {
  height: 100%;
  width: 100%;
  /* height: auto;
  width: auto; */
}

.line {
  width: 100%;
  height: 2px;
  background-color: black;
  margin-bottom: .5%;
}

.mobile_buttons_div {
  margin-top: 1%;
  justify-content: center;
  display: flex;
  /* background-color: lightgray; */
}

/* @media (max-width: 450px) {

  .col_image_div {
    width: 50%
  }

  .row_padding_left{
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    flex-wrap: nowrap;
  }
} */

@media (max-width: 768px) {
  /* .col-sm-2 {
    width: 30%;
  } */
  .col_div_record {
    text-align: left;
  }
  
  .visit_record_div {
    font-size: 6px;
  }
}

@media (max-width: 1024px) {

  /* .col_image_div {
    width: 10%;
  } */
}

/* CSS for screens between 800px and 1300px */
@media screen and (min-width: 575px) and (max-width: 800px) {
  /* CSS rules specific to this screen size range */
  .col_image_div {
    /* width: 35% */
    width: 17%
  }
  
}
@media screen and (min-width: 801px) and (max-width: 909px) {
  /* CSS rules specific to this screen size range */
  .col_image_div {
    /* width: 35% */
    width: 12%
  }
  
}
@media screen and (min-width: 910px) and (max-width: 1500px) {
  /* CSS rules specific to this screen size range */
  .col_image_div {
    /* width: 35% */
    width: 10%
  }
}

@media screen and (min-width: 1501px) {
  /* CSS rules specific to this screen size range */
  .col_image_div {
    /* width: 35% */
    width: 7%
  }
}

@media only screen and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles for iPad Mini in landscape mode */
  /* .col_image_div { */
    /* width: 20%; */
    /* padding-right: 20%;
  } */

  .col_image_div {
    /* width: 35% */
    width: 10%
  }

  /* .acquire_media_div {
    display: none;
  } */
}

@media only screen and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles for iPad Mini in landscape mode */

  /* removing the acquire media and camera icons in ipad */
  /* .acquire_media_div {
    display: none;
  } */
}

@media only screen and (max-device-width: 1368px) and (orientation: landscape) {
  /* Styles for iPad Air in landscape mode */
  .col_image_div {
    /* width: 30%; */
    /* padding-right: 10%; */
  }

  /* removing the acquire media and camera icons in ipad */
  /* .acquire_media_div {
    display: none;
  } */
}

@media only screen and (max-device-width: 768px) and (orientation: portrait) {
  /* Styles for iPad Mini in landscape mode */

  /* This is to make the width of the image col smaller to bring the name div closer on a medium screen */
/* .row_padding_left .col-md-2 {
  width: 12%;
} */

.col_image_div {
  width: 14%;
  /* padding-right: 10%; */
}

.visit_record_div h4 {
  margin: 0;
  line-height: 1.2;
  /* line-height: .1; */
  /* display: flex;
  align-items: baseline; */
  /* padding-left: 20px; */
  display: flex;
  align-items: baseline;
}
}

@media only screen and (max-device-width: 820px) and (orientation: portrait) {
  /* Styles for iPad air in landscape mode */

  /* This is to make the width of the image col smaller to bring the name div closer on a medium screen */
/* .row_padding_left .col-md-2 {
  width: 12%;
} */

.col_image_div {
  width: 14%;
  /* padding-right: 10%; */
}

.visit_record_div h4 {
  margin: 0;
  line-height: 1.2;
  /* line-height: .1; */
  /* display: flex;
  align-items: baseline; */
  /* padding-left: 20px; */
  display: flex;
  align-items: baseline;
}
}

/* .login-modal-z-index {
  z-index: 9999;
}

.login-backdrop-z-index {
  z-index: 9997;
} */

@media only screen and (min-device-width: 1900px) {
  /* Styles for iPad Air in landscape mode */
  /* .col_image_div {
    width: 7%
  } */

  /* removing the acquire media and camera icons in ipad */
  /* .acquire_media_div {
    display: none;
  } */
}

@media (max-width: 450px) {

  .col_image_div {
    width: 30%
  }

  .row_padding_left{
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    flex-wrap: nowrap;
    /* overflow-x: auto; */
    /* border-bottom: black; */
    border-bottom: none;
  }

  .row_padding_left2{
    /* --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x)); */
    border-bottom: 2px solid black;
    margin-bottom: .5%;
  }
}

.grid-container {
  display: grid;
  /* Two columns: auto sizes based on content */
  /* grid-template-columns: auto auto; */
  grid-template-columns: 1fr 3fr;
  column-gap: 10px; /* Add gap between columns */
}

.grid-item {
  grid-column: span 1; /* Each item takes one column */
}

.columns_container {
  display: flex;
}

.individual_column {
  flex: 1;
  /* display: flex;
  flex-direction: column; */
}

.pat_first_col{
  padding-right: 10%;
}

.column2 {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 10px;
}

.dob_select_style {
  display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s 
}

.country_dropdown {
  -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0!important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    /* width: 10% */
  
}


/* .country_dropdown > div:first-child,
.country_dropdown > div:last-child {
  width: 100%; 
} */
.two_column_container {
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.patient_column {
  /* width: 50%;
  padding: 10px; */
  /* padding: 10px; */
  /* border: 1px solid #ccc; */
}

.icon-pointer {
  cursor: pointer;
}