span.label, label {
    font-size: 16px ;
}

span.draw-draw, span.select-select {
    color: rgb(13,111,254);
    font-weight: 700;
}

span.draw-select, span.select-draw {
    color: gray;
    font-weight: lighter;
}

.form-switch .form-check-input {width: 1.8em; height: 1em;}

.label-sm {
    color: rgb(13,111,254);
}

.label-md {
    color: rgb(13,111,254);
}

input[type="color"] {
    width: 30px;
}