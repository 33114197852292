.title {
    position: absolute;
    font-size: 1.2rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
}

.title2 {
    position: relative;
    font-size: 1.2rem;
    /* left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
    color: white;
}

/* @media (max-width: 450px) {
    .NavDropDown_dd_pad__-m3xa {
        padding: none;
    }
    
} */

@media (max-width: 968px) {
    .title {
    position: absolute;
    justify-content: center;
    /* left: 50%;
    top: 50%; */
    font-size: 1.2rem;
    vertical-align: middle;
    justify-items:center;
    transform: translate(-30%, -50%);
    }
    
}

.user_icon {
    font-size: 3rem;
    margin-right: 25px;
    color: white;
}

.house {
    font-size: 2rem;
    padding-right: 25px;
    margin-right: 3rem;
    color: white;
}

.navlogo {
    color: 'white';
    margin-left: '1.5rem';
 }

 .locname {
    color: white;
    font-size: 1.2rem;
 }