
/* these classes shared by charts - eFace and Face Instruments */

.charts-center {
    text-align: center;
}

.charts-sm {
    font-size: 0.8em;
}
.charts-full {
    font-size: 1em;
}


  
ul.legend {
    list-style-type: none;
    text-align: center;
}

ul.legend > li {
    display: inline-block;
    float: none;
    /* margin-left: 20px; */
    /* margin-right: 20px; */
    /* You can also add some margins here to make it look prettier */
}

ul.legend li {
    margin: 0 1em 0 1em;
}


.dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #bbb;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    margin-right: 5px;
}
  
.custom-tooltip-full {
    /* border: 2px solid #f7f7f7; */
    /* border: 1px solid black; */
    background: #f6f6f6;
    font-size: 1.3em;
  
    /* z-index: 9999; */
}

.custom-tooltip-sm {
    /* border: 2px solid #f7f7f7; */
    /* border: 1px solid black; */
    background: #f6f6f6;
    font-size: 0.9em;

    /* z-index: 9999; */
}

.tooltip-header {
    text-align: center;
    color: '#ddd';
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

/* add outline for better vsibility: -webkit-text-stroke-... */
ul.ul-tooltip {
    list-style-type: none !important;
    margin: 0;
    padding: 0;
    font-size: 0.9em;

  
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: gray;
}

.link {
    text-decoration: underline;
    color: rgb(19, 125, 246);
    cursor:pointer;
}



