.redBorder {
    border-color: red !important;
    border-width: 2px !important;
}

.greenBorder {
    border-color: green !important;
    border-width: 2px !important;
}

.divInlineBlock {
    display: inline-block;
}