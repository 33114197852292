.hr_line {
    margin-top: 1px;
    margin-bottom: 1px;
}

.muted {
    color: gray;
}

.inherit {
    color: inherit;
}