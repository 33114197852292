@media all {
    .print-only {
        display: none !important;
    }
}

@media print {
    @page { margin: 3em !important; }
    .print-only {
        display: inline-block !important;
    }
}


.prov_followup_history_of {
    /* margin-right: 30px; */
    margin-left:0px;
    margin-right: 5px;
    /* margin-bottom: 10px; */
    padding: 10px;
    border: 1px solid gray;
} 

.prov_followup_history_of label
, .prov_followup_brow_height label {
    margin-left:5px;
} 

.prov_followup_history_of div {
    margin-bottom:2px;
} 


.prov_followup_functional label
, .prov_followup_referring label
, .prov_followup_exam label
, .prov_followup_jowling label  {
    margin-right:10px;
}

input[type=checkbox], input[type=radio] {
    margin-right:10px;
}

.prov_followup_history_of > div
, .prov_followup_brow_height > div {
    flex: 0 0 auto;
    width: 33.3333%;
}

.prov_followup_glabella > div,
.prov_followup_nasolabial_folds > div,
.prov_followup_eyes > div,
.prov_followup_neck > div,
.prov_followup_cranial_nerve > div,
.prov_followup_external_ear > div,
.right_ext_nasal_val_narrow > div {
    flex: 0 0 auto;
    width: 20%;
}

.nasal_cavity_mass > div {
    flex: 0 0 auto;
    width: 33.3333%;
}


.bottom_border {
    /* border-bottom: 1px solid gray; */
    padding-top: 10px;
    margin-bottom: 15px;
}

.right_ext_nasal_val_narrow > div {
    padding-bottom: 0.6em;
}

.fu_textarea {
    resize: vertical;
    width: 100%;
    height: 30px;
}

/* remove horizontal line after the bordered group for History Of */
div:has(.prov_followup_history_of) > hr {
    display: none;
}

hr {
    margin-left: 5px;
    margin-right: 5px;
}

.link {
    text-decoration: underline;
    color: rgb(19, 125, 246);
    cursor:pointer;
}

.alert-title {
    color: red;
    font-weight: bold;
    font-size: 1.3em;
}

.alert-title-black {
    color: black;
    font-weight: bold;
    font-size: 1.3em;
}