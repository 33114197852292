.IntraopImg {
    display:inline-block;
    border: 1px solid gray;
    /* width: auto; */
    /* height: auto; */
    width: 100%;
    height: 100%;
  }

  .visitImageSelected{
    border: 3px solid black;
  }

  .padding_underneath_thumbnails {
    padding-bottom: 2%;
  }

  .intraop_img_return {
    width: 100%;
    height: auto;
    padding: 2px;
    display:inline-block;
    border: 1px solid gray;
    /* border: 1px solid transparent; */
    /* border-color: transparent; */
    width: auto;
    height: auto;
  }

  .intraop_img_selected {
    border: 3px solid black;
  }

  /* .visit-image-selected{
    border: 3px solid black;
  } */
 .intraop_border_not_selected {
    /* border: 3px double lightgray; */
    border: 3px double transparent;
  }

  .intraop_border_selected {
      border: 3px solid black;
  } 