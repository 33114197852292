.dd_pad {
    padding: 0px 40px;
}

.dropdowntogglepadding {
    padding: 10px 15px;
    background-color: #27829E;
    border-color: #27829E;
}

.dropdowntogglepadding:hover, .dropdowntogglepadding:active, .dropdowntogglepadding:focus {
    background-color: #27829E !important;
    border-color: #27829E !important;
}

/* .dropdownicon {
    padding: 0px 10px;
    width: 100%;
} */

.user_icon {
    font-size: 2rem;
    margin-right: 5px;
    color: white;
}

.nav-button {
    background-color: white;
  }

  .logout_btn {
    margin-right: 1.75rem;
    color: white;
    border-color: white;
}

.login_btn {
    margin-right: 2.0rem;
    color: white;
    border-color: white;
}

.site_loc {
    background-color: #27829E;
    border-color: rgb(70, 68, 68);
    
}

.site_loc:hover {
    background-color: #216f85;
    border-color: rgb(172, 169, 169);
}



/* .site_loc:checked {
    background-color: lightgray !important;
    border-color: rgb(46, 45, 45);
    color: black !important;
} */

.site_loc:active {
    background-color: lightgray !important;
    border-color: rgb(46, 45, 45);
    color: black !important;
}