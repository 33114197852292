
/*
botox_text_area  {
 
}
*/
/* .container {
  margin-left: 0;
  padding-bottom: 1%;
} */

.top_row_botox_area{
  margin-left: 0;
  padding-bottom: .2%;
}

.botox_top_row_area {
  display: flex;
  /* justify-content: space-evenly; */
  justify-content: space-between;
  padding-bottom: .5%;
}

.botox_text_area {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: .2%;
}


.botox_text_area textarea {
    color: silver;
    /* width: 90%; */
    /* width: 10px */
    /* --bs-gutter-x: 1 rem; */
      }

.red_box {
    background-color: red;
  }
  .blue_box {
    background-color: blue;
  }   


.botox_type_div {
  display: inline-block;
  width: 20%;
  padding-right: 5px;
}

.prime_canvas_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  /* width: 100%; */
  /* margin-left: 10px; */
  /* height: 100vh;
  width: 100%; */
}

.canvas_wrapper {
  /* position: absolute; */
  position: relative;
  /* height: 663;
  width: 1337; */
    max-height: 663;
  max-width: 1337;
  /* max-height: 100%;
  max-width: 100%; */
  border: 2px solid;
  margin: 0 auto;
  /* overflow: auto; */
  /* overflow-x: scroll; */
  /* flex: 0 0 auto;
  box-sizing: border-box; */
  /* justify-content: center;
  align-items: center;
  display: flex; */
  /* padding-bottom: .5%; */
}

/* @media screen and (max-width: 768px) {
  .canvas_wrapper {
    width: 100%;
    height: auto;
  }
} */

@media screen and (max-width: 820px) {
  .prime_canvas_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
  }

  .botox_action_buttons_styling button {
    /* This is to control the height of the buttons in the botox map */
    max-height: 2em; /* replace with your desired maximum button height */
    overflow: hidden;
    width: 90%;
  }
}

@media screen and (min-width: 1024px) {
  .prime_canvas_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
  }
}

.buttons_below_canvas {
  position: relative;
  /* position: absolute; */
  width: 100%;
  padding-top: .2%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* top: 670px; */
}

.botox_color_box{
  padding-bottom: .05%;
  padding-top: .1%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.other_info_form{
  padding-bottom: .05%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.buttons_below_spacing {
  margin-right: 1%;
}

.botox_action_buttons_styling {
  display: flex;
  /* justify-content: space-evenly; */
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: .2%;
}

.botox_action_buttons_styling button {
  width: 90%;
}

.botox_action_buttons_styling div {
  flex: 2;
}

/* .botox_action_buttons_styling button { */
  /* This is to control the height of the buttons in the botox map */
  /* replace with your desired maximum button height */
  /* max-height: 2em; 
  overflow: hidden; */
/* } */

.outer_div_botox_buttons{
  padding-bottom: .05%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* max-width: 1337; */
  /* justify-content: center; */
  /* width: 1337px; */
}

.modalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttons_below_spacing {
  margin-right: 1%;
}