.white {
    background-color: white;
    /* opacity: 0.5 !important; */
}

/* .modal-backdrop {
    opacity: 0.5 !important;
} */

div.fullscreen {
    background-color: white;
    width: 100%;
    height: 100%;    
}

/* #logindb.fade.show {
    opacity: 1 !important;
} */

.inactivityBackdrop {
    /* opacity: 0.5; */
    background-color: gray;
}