.one-note-note {
    width: 100%;
    height: auto;
    /* border-bottom: 1px solid black; */
    /* margin-top: 10px; */
    padding: 10px 15px 10px 15px;
}

.one-note-the-note {
    /* border: 1px solid gray; */
    /* margin: 15px 15px 15px 15px; */
    width:100%;
    /* margin-top: 10px; */
    overflow: auto;
    white-space: pre-wrap;
  }

.one-note-date {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 1.2em;
}

.one-note-separator {
    width:90%;
    height: 3px;
    background-color: black;
    /* opacity 0.25 comes from somewhere - overwrite it here */
    opacity: 1;
}

