.divInlineBlock {
    display: inline-block;
    width: -moz-fit-content;
    width: fit-content;
}

.visitPdfNote {
    height: 300px;
    /* width: 90%; */
    width: 96vw;
    margin-left: auto;
    margin-right: auto;;
}