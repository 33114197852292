.admin_header {
    color: black; 
    /* padding-left: 44%; */
    text-align: center;
}

.admin_row_padding {
    padding-bottom: 5px;
}

.admin_button_style {
    width: 175px;
    height: 50px;
    color:#27829E;
    border-color: #27829E;
}

.admin_button_style:hover {
    background-color:#27829E;
    border-color: #27829E;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);

  }

  .no-border-between-columns td {
    border: none;
  }

  .error_message_red_color {
    color: red;
  }
  
  /* .no-border-between-columns td:last-child {
    border-right: 1px solid;
  } */
  /* table {
    border-collapse: separate;
    border: 1px solid;
  }
  
  table td {
    border: 1px solid;
  } */
  


  @media only screen and (max-device-width: 1024px) and (orientation: landscape) {
    /* Styles for iPad Mini in landscape mode */
    .admin_button_style {
        max-height: 4em; /* replace with your desired maximum button height */
        overflow: hidden;
        width: 100%;
        color:#27829E;
        border-color: #27829E;
    }

    /* .nav_link_css{
        align-items: center;
        justify-content: center;
    } */
  }

  @media only screen and (max-device-width: 912px) and (orientation: portrait) {
    /* Styles for iPad Mini in portrait mode */
    .nav_link_css{
        align-items: center;
        justify-content: center;
    }

    .admin_row_padding {
      padding-bottom: 5px;
      margin-left: calc(1.5 * var(--bs-gutter-x));
  }
    .admin_row_padding2 {
        margin-left: calc(1.5 * var(--bs-gutter-x));
    }
  }

  @media only screen and (max-device-width: 450px) and (orientation: portrait) {
    /* Styles for iPad Mini in portrait mode */
    .nav_link_css{
        align-items: center;
        justify-content: center;
    }

    .admin_row_padding {
      padding-bottom: 5px;
      margin-left: calc(.2 * var(--bs-gutter-x));
  }
    .admin_row_padding2 {
        margin-left: calc(.2 * var(--bs-gutter-x));
    }
  }

  @media only screen and (max-device-width: 390px) and (orientation: portrait) {
    /* Styles for iPad Mini in portrait mode */
    .nav_link_css{
        align-items: center;
        justify-content: center;
    }

    .admin_button_style {
      max-height: 3.5em; /* replace with your desired maximum button height */
      overflow: hidden;
      width: 100%;
      color:#27829E;
      border-color: #27829E;
  }

    .admin_row_padding {
      padding-bottom: 5px;
      margin-left: calc(-.5 * var(--bs-gutter-x));
  }
    .admin_row_padding2 {
        margin-left: calc(-.5 * var(--bs-gutter-x));
    }
  }