
.note-frame {
    background-color: #eeeded;
    border-width: 2px;
    border-radius: 5px;
}

.notes {
    position: fixed;
    width: 80%;
    z-index: 20;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: solid black 1px;
}

.h60 {
    height: 60px;
}

.close-button {
    cursor: pointer;
}