.hr_line {
    margin-top: 1px;
    margin-bottom: 1px;
  }

 /* .main_intv_div{
  
 }  */

 /* .intervention_list{
  margin-left: 0;
  padding-left: 0;
  font-size:smaller;
 } */

  /* .intervention_list{
  padding-left: 0;
  text-indent: -10px;
 } */

 /* .intervention_list_item { */
  /* margin-left: 0; */
  /* padding-left: 0px;
  text-indent: -10px; */
/* } */

.muted {
  color: gray;
}

.inherit {
  color: inherit;
}