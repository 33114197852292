/* .spinner {
    width: 100%;
    height: 70px;
    display: "flex";
    justify-content: "center";
    align-items: "center";
    background-color: cornflowerblue;
    margin-left: auto;
    margin-right: auto;
} */

.spinner {
    /* background-color: lightskyblue; */
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -50px 0 0 -50px;
    z-index: 100;
    text-align: center;
    /* color:darkblue;
    font-size: 1.2em; */
}

.spinner-2 {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -50px 0 0 -50px;
    z-index: 100;
}

