.botoxImg {
    display:inline-block;
    border: 1px solid gray;
    /* width: auto; */
    /* height: auto; */
    width: 100%;
    height: 100%;
  }

  .visitImageSelected{
    border: 3px solid black;
  }

  .padding_underneath_thumbnails {
    padding-bottom: 2%;
  }

  .botox_border_not_selected {
    /* border: 3px double lightgray; */
    border: 3px double transparent;
  }

  .botox_border_selected {
      border: 3px solid black;
  } 