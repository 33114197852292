

label {
    font-size: 1.3rem;
}

div.outer-circle {
    position: reative;
    /* width:50px;
    height:50px; */
}

/* label.size {
    margin-top: 27px;
    position:absolute;
    z-index: 2;   
} */

div.brush-size {
    position:relative;
    border-radius: 50%;
}





input[type=range][orient=horizontal] {
    writing-mode: horizontal-lr;   
    -webkit-appearance: slider-horizontal;
    appearance: slider-horizontal;
    /* width: 150px; */
    /* width: auto; */
    /* width: 150px; */
    vertical-align: top;
    
}


.slider-large > input[type=range][orient=horizontal] {
    width: 150px;
}

.slider-small > input[type=range][orient=horizontal] {
    width: 100px;
}


datalist {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    writing-mode: horizontal-lr;
    /* width: 150px; */
}

.slider-large > datalist {
    width: 150px;
}
.slider-small > datalist {
    width: 100px;
}

div.brush-outline {
    background: none;
    border-radius: 50%;
    border-style: dashed;
    border-color: grey;
    border-width: 1px;
}



input[type=color] {
    position:absolute; 
    visibility: hidden;
}

label.label-size {
    font-size: 1.5em;
    font-weight: bolder;
}

label.label-size-disabled {
    font-size: 1.5em;
    font-weight: bolder;
    opacity: 0.5;
}

.colors-frame {
    background-color: #eeeded;
    border-width: 2px;
    border-radius: 5px;
}

.colors {
    position: fixed;
    /* width: 80%; */
    width: 678px;
    z-index: 20;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: solid black 1px;
}

.color-row {
    height: auto;
    width: auto;
    margin-bottom: 10px;
}

.color {
    display:inline-block;
    width: 40px;
    height: 40px;
    outline: solid black 1px;
    margin-right: 14px;
}

.current-color {
    outline: double black 8px;
    /* padding: 2px 2px 2px 2px; */
}

.color-picker-header {
    padding-bottom: 10px;
}

.fa-pencil {
    border: #0d6efd solid 1px;
    border-radius: 5px;
    padding: 4px 4px 4px 4px;
    background-color: #eeeded;
}

.fa-pencil:hover {
    outline: solid black 2px;
}

.fa-pencil-disabled {
    border: #66a1f9 solid 1px;
    border-radius: 5px;
    padding: 4px 4px 4px 4px;
    opacity: 0.5;
    /* background-color: #eeeded; */
}

.brush-line {
    /* background-color: black; */
    width: 50px;
    display:inline-block;
    margin-right: 2px;
    /* outline: double 4px black; */
}

.line-x {
    background-color: black;
    width: 40%;
    display:inline-block;
    margin-right: 4px;
}

/* .line-1 {
    height: 1px;
}

.line-2 {
    height: 2px;
}

.line-3 {
    height: 3px;
}

.line-4 {
    height: 4px;
}

.line-5 {
    height: 5px;
}

.line-6 {
    height: 6px;
}

.line-7 {
    height: 7px;
}

.line-8 {
    height: 8px;
}

.line-9 {
    height: 9px;
}

.line-10 {
    height: 10px;
}


.line-11 {
    height: 11px;
}

.line-12 {
    height: 12px;
}

.line-13 {
    height: 13px;
}

.line-14 {
    height: 14px;
}

.line-15 {
    height: 15px;
}

.line-16 {
    height: 16px;
}

.line-17 {
    height: 17px;
}

.line-18 {
    height: 18px;
}

.line-19 {
    height: 19px;
}

.line-20 {
    height: 20px;
}


.line-21 {
    height: 21px;
}

.line-22 {
    height: 22px;
}

.line-23 {
    height: 23px;
}

.line-24 {
    height: 24px;
}

.line-25 {
    height: 25px;
}

.line-26 {
    height: 26px;
}

.line-27 {
    height: 27px;
}

.line-28 {
    height: 28px;
}

.line-29 {
    height: 29px;
}

.line-30 {
    height: 30px;
}


.line-31 {
    height: 31px;
}

.line-32 {
    height: 32px;
}

.line-33 {
    height: 33px;
}

.line-34 {
    height: 34px;
}

.line-35 {
    height: 35px;
}

.line-36 {
    height: 36px;
}

.line-37 {
    height: 37px;
}

.line-38 {
    height: 38px;
}

.line-39 {
    height: 39px;
}

.line-40 {
    height: 40px;
}


.line-41 {
    height: 41px;
}

.line-42 {
    height: 42px;
}

.line-43 {
    height: 43px;
}

.line-44 {
    height: 44px;
}

.line-45 {
    height: 45px;
}

.line-46 {
    height: 46px;
}

.line-47 {
    height: 47px;
}

.line-48 {
    height: 48px;
}

.line-49 {
    height: 49px;
}

.line-50 {
    height: 50px;
} */


.brush-thickness {
    cursor:pointer;
    padding-top: 5px;
    padding-bottom: 0px;
    padding-left: 5px;
    opacity: 1;
    /* height:54px; */
}

.brush-thickness-disabled {
    padding-top: 5px;
    padding-bottom: 0px;
    padding-left: 5px;
    opacity: 0.5;
}

.brush-thickness:hover, .label-size:hover {
    background-color: rgb(222, 222, 222);
}

.current-size {
    outline: double black 4px;
}