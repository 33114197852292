.alert-title {
    color: red;
    font-weight: bold;
    font-size: 1.6em;
}

.alert-prompt {
    padding-top: 30px;
    padding-bottom: 30px;;
}

.alert-prompt > h3 {
    color: gray;
    font-weight: bold;
    font-size: 1.8em;
    text-align: center;
}

.error-msg {
    color: red;
}
