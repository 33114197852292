@media screen and (max-height: 1200px) {
    .himPageLocation {
        /* position: relative; */
        top: 5rem;
    }
}

@media screen and (max-height: 900px) {
    .himPageLocation {
        /* position: relative; */
        top: 2rem;
    }
}

@media screen and (max-height: 600px) {
    .himPageLocation {
        /* position: relative; */
        top: 0.5rem;
    }
}


.title {
    text-align: center;
}

.himPageLocation {
    position: relative;
    /* top: 7rem; */
}


.headerRow {
    width:100%;
    background-color: lightgray;
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;;
}



.containterDiv {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid gray;
    /* padding: 10px 10px 10px 10px; */
    padding-bottom: 20px;
}

.h4 {
    text-align: center;
    padding-bottom: 10px;;
}


.himUl {
    list-style-type: none;
    padding-top: 20px;
    padding-bottom: 20px;
}

.himUl > li {
    padding-bottom: 5px;
}

.himLi {
    padding-bottom: 3px;
    padding-left: 10px;
}

.himLi + label {
    font-weight: bold;
}

/* used to bold label under li */
li > label:first-child {
    font-weight: bold;
}

/*.himLi > input,*/ input[type=checkbox] {
    margin-right: 10px;
}

.himMedia {
    font-weight: bold;
}


@media (width < 992px) {
    .selectAll {
        text-align: left;
        padding-left: 2rem;
    }
}

@media (width >= 992px) {
    .selectAll {
        text-align: center;
    }
}

.form {
    border-radius: 10px;
    border: 1px solid #d0d0d0;
    padding-bottom: 15px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

.formHeader {
    width: 100%;
    border-bottom: 1px solid #bbb;
    padding: 10px 10px 10px 15px;
    background-color: #f0f0f0;
    font-weight: bold;
  }

  .formInnerDiv {
    margin: 15px 15px 15px 15px;
    /* height:250px; */
    /* overflow-y: auto;
    overflow-x:hidden; */

  }

  /* .bold {
    font-weight: bold;
  } */

  .patientSearchHeader {
    font-weight: bold;
    font-size: 1.285em;
    padding-bottom: 3px;;
    text-align: center;
    width:100%
  }

  .w100 {
    width: 100%;
  }

  .errors {

    padding-bottom: 20px;
  }

  .errors span {
    color: red;
    display: block;
    padding-left: 20px;
    padding-bottom: 3px;
  }

  .dateError {
    border: 2px solid red;
  }

  .error {
    list-style-type: none;
    color: red;
  }


  .div_errors {
    min-height: 90px;
    width: 100%;
  }


  .alert-title {
    color: red;
    font-weight: bold;
    font-size: 1.6em;
}

.alert-title-black {
    color: black;
    font-weight: bold;
    font-size: 1.3em;
}

.alert-title-orange {
    color: orange;
    font-weight: bold;
    font-size: 1.3em;
}

.alert-title-red {
    color: orangered;
    font-weight: bold;
    font-size: 1.3em;
}

.alert-prompt {
    padding-top: 30px;
    padding-bottom: 30px;;
}

.alert-prompt > h3 {
    color: gray;
    font-weight: bold;
    font-size: 1.8em;
    text-align: center;
}

.alert-prompt-black > h3 {
    color: black;
    font-weight: bold;
    font-size: 1.8em;
    text-align: center;
}