.canvas-border {
    height: fit-content;
    /* position: absolute; */
    /* top: 0; */
}

.hidden_canvas {
    position: absolute;
    top: -3000;
    left: -3000;
}