.label-bold {
    font-weight: bold;
}

.section {
    width:95%;
    border-radius: 10px;
    border: 1px solid #d0d0d0;
    padding-bottom: 1em;
    padding-top: 1em;
    margin-bottom: 1em;
    margin-top: 1em;
}

.section-no-border {
    width:95%;
    /* border-radius: 10px;
    border: 1px solid #d0d0d0; */
    padding-bottom: 1em;
    padding-top: 1em;
    /* margin-bottom: 1em;
    margin-top: 1em; */
}

.errorMsg {
    color:red;
    padding-left: 10px;
}

.alert-title {
    color: red;
    font-weight: bold;
    font-size: 1.6em;
}

.alert-prompt {
    padding-top: 30px;
    padding-bottom: 30px;;
}

.alert-prompt > h3 {
    color: gray;
    font-weight: bold;
    font-size: 1.8em;
    text-align: center;
}