
.modal-backdrop.show {
    opacity: 1 !important;
}

.modal-title {
    text-align: center;
    width: 100%;
    color: #0d6efd;
}

.btn-large {
    min-height: 50px;
    height: 50px;
    
}

.message {
    color: red;
}

.password-input-container {
    position: relative;
  }
  
  .password-toggle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;
  }