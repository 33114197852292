
@media all {
    .print-only {
        display: none !important;
    }
}

@media print {
    @page { margin: 3em !important; }
    .print-only {
        display: inline-block !important;
    }
}


/* .followup_photos
, .followup_location
, .followup_billing
, .followup_or {
    margin-left:0px;
    margin-right: 5px;
    padding: 10px;
    border: 1px solid gray;
}  */

.folowup_photos label
, .followup_location label
, .followup_billing label
, .followup_or label {
    margin-left:5px;
} 

.followup_photos div
, .followup_location div
, .followup_billing div
, .followup_or div {
    margin-bottom:2px;
} 


.prov_followup_functional label
, .prov_followup_referring label
, .prov_followup_exam label
, .prov_followup_jowling label  {
    margin-right:10px;
}

input[type=checkbox], input[type=radio] {
    margin-right:10px;
}

.followup_photos > div
, .followup_or > div

, .followup_location > div
, .followup_billing > div 
, .followup_special_orders > div 
, .followup_redcap > div
{
    flex: 0 0 auto;
    width: 20%;
}

/* .followup_location > div {
    flex: 0 0 auto;
    width: 33.3333%;
} */

/* .followup_billing > div 
, .followup_special_orders > div {
    flex: 0 0 auto;
    width: 25%;
} */

.prov_followup_glabella > div,
.prov_followup_nasolabial_folds > div,
.prov_followup_eyes > div,
.prov_followup_neck > div,
.prov_followup_cranial_nerve > div,
.prov_followup_external_ear > div,
.right_ext_nasal_val_narrow > div {
    flex: 0 0 auto;
    width: 20%;
}

.nasal_cavity_mass > div {
    flex: 0 0 auto;
    width: 33.3333%;
}


.bottom_border {
    /* border-bottom: 1px solid gray; */
    padding-top: 10px;
    margin-bottom: 15px;
}

.right_ext_nasal_val_narrow > div {
    padding-bottom: 0.6em;
}

.fu_textarea {
    resize: vertical;
    width: 100%;
    height: 30px;
}

/* remove horizontal line after the bordered group for History Of */
div:has(.prov_followup_history_of) > hr {
    display: none;
}

hr {
    margin-left: 5px;
    margin-right: 5px;
}

.link {
    text-decoration: underline;
    color: rgb(19, 125, 246);
    cursor:pointer;
}

.alert-title {
    color: red;
    font-weight: bold;
    font-size: 1.3em;
}

.alert-title-black {
    color: black;
    font-weight: bold;
    font-size: 1.3em;
}

.level_ {
    margin-left: inherit;
}

.level_2 {
    margin-left: 1.5em;
}






