.all-notes {

    border: 1px solid gray;
    padding: 10px 10px 10px 10px;
    margin: 10px 10px 10px 10px;
    /* background-color: rgb(239, 239, 241); */
    


    /* width: 100%; */
    width: auto;
    height: auto;
    overflow-y: auto;

    /* border-bottom: 1px solid #bbb; */
    background-color: #f0f0f0;

    border-radius: 10px;
    /* border: 1px solid #d0d0d0; */
    padding-bottom: 15px;
    
}

.section-header {
    padding-bottom:10px;
    border-bottom: 1px solid gray;
}

/* .visit-dialog-form-header {
    width: 100%;
    border-bottom: 1px solid #bbb;
    padding: 10px 10px 10px 15px;
    background-color: #f0f0f0;
  } */

  /* .visit-dialog-form {
    border-radius: 10px;
    border: 1px solid #d0d0d0;
    padding-bottom: 15px;
    margin-bottom: 20px;
  } */


