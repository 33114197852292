.header {
    background-color: rgb(133, 218, 246);
    /* background-color: rgb(1, 92, 122); */
}

.title {
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* transform: translate(-50%, -50%); */
}

.dept_header {
    font-size: 1.3rem;
}

.buffer {
    margin-left: 0.5rem;
}

input[type=radio], input[type=checkbox] {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

.line {
    border: 3px solid black;
}

.hidden {
    display: none;
}

select.error {
    border: 1px solid red; 
}

label.error {
    color: red;
}

.handle_overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap;  */
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    width: 300px;
    white-space: nowrap;
    vertical-align: middle;
    
}