ul.no-bullets {
    list-style-type: none;
    padding: 0;
    margin-left: 01em;
}

ul.red {
    color:red;
}

div.fullscreen {
    background-color: white;
    width: 100%;
    height: 100%;
    opacity: 1 !important;
    
}

.white {
    background-color: white;
    opacity: 1 !important;
}

.gray {
    background-color: gray;
    opacity: 1 !important;
}