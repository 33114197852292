#fileUpload {
  display: none;
}
#form-file-upload {
    height: 16rem;
    width: 28rem;
    max-width: 100%;
    text-align: center;
    position: relative;
  }
  
  #input-file-upload {
    display: none;
  }
  
  #label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
  }
  
  #label-file-upload.drag-active {
    background-color: #ffffff;
  }
  
  .upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
  }
  
  .upload-button:hover {
    text-decoration-line: underline;
  }
  
  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }


    /* These two are for the BotoxImageUpload component to hide the default upload  */
    #botoxImageUpload {
      display: none;
    }
  
    #input-botox-image-upload {
      display: none;
    }

    /* These two are for the VisitUploadImage component to hide the default upload  */
    #profilePicUpload {
      display: none;
    }
  
    #input-profile-pic-upload {
      display: none;
    }

    .uploaded-files-list-scrollable {
        height:auto;
        overflow-y: auto;
        max-height: 30px;
    }

    .button_container_label {
      display: flex;
      justify-content: space-between;
    }

    .botox-src-img-button {
      flex-grow: .9;
    }

    @media screen and (max-width: 820px) {
    
      .botox-src-img-button {
        /* This is to control the height of the upload button in the botox map */
        max-height: 2em; /* replace with your desired maximum button height */
        overflow: hidden;
      }
    }

    @media screen and (min-width: 1024px) {
    
      .botox-src-img-button {
        /* This is to control the height of the upload button in the botox map */
        max-height: 2.5em; /* replace with your desired maximum button height */
        overflow: hidden;
      }
    }

    .botox-src-img-button label {
      width: 90%;
      /* flex: 2; */
    }

    /* datepicker with custom input - to combine in one button */
    .file-upload-button, .file-upload-button label {
        cursor: pointer;
    }
    .datepickerWrapper {
        display: block;
    }

    .dpickerInput {
        display: block;
        background-color: #0d6efd;
        color: white;
        border: none;
        text-decoration: underline;
        cursor: pointer;
    }

    .datepickerTooltip > .tooltip-inner {
        background-color: #0165fb;
        color: white;
        border: 1px solid #062e56;
    }

    .datepickerTooltip > .tooltip-arrow {
        border-top: 0px solid #0165fb;
    }