.modal_container {
    /* position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999; */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10001;
  }
  
  .custom_modal {
    /* background-color: transparent;
    box-shadow: none; */
    z-index: 10000;
  }
  
  .black_overlay {
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.8); */
    background-color: rgba(0, 0, 0, 1.0);
    /* z-index: 9000; */
    z-index: 7000;
  }