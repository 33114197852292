.dashboardheader {
    text-align: center;
}

.dashboard_before_footer {
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 20%;
    /* padding-bottom: 1%; */
}



@media only screen and (max-device-width: 768px) and (orientation: portrait) {
    /* Styles for iPad Mini in portrait mode */
    .dashboard_before_footer {
      padding-bottom: 40%; /* Adjust the value as needed */
    }
  }

  @media only screen and (max-device-width: 820px) and (orientation: portrait) {
    /* Styles for iPad Mini in portrait mode */
    .dashboard_before_footer {
      padding-bottom: 40%; /* Adjust the value as needed */
    }

    .div_blank_space{
        width: 0%
    }
  }

  @media only screen and (max-device-width: 450px) and (orientation: portrait) {
    .dashboard_before_footer {
      padding-bottom: 100%;
      overflow-x: auto;
    }
  }
  
  
  @media only screen and (max-device-width: 1024px) and (orientation: landscape) {
    /* Styles for iPad Mini in landscape mode */
    .dashboard_before_footer {
      padding-bottom: 30%; /* Adjust the value as needed */
    }
    .col-lg-8 {
        flex: 0 0 auto;
        width: 80%;
    }
  }

  @media only screen and (max-device-width: 1180px) and (orientation: landscape) {
    /* Styles for iPad Air in landscape mode */
    .col-lg-8 {
        flex: 0 0 auto;
        width: 80%;
    }
  }

.patient_search_header {
    text-align: center;
}

.search_row_div {
    justify-content: center;
    padding-bottom: 1.5%;
}

.dropdown_row_div {
    justify-content: left;
    padding-bottom: 1%;
}

.positionsearch {
    display: flex;
    justify-content: center;
    align-items: center;
    left: 100%;
    gap: 10%;
}

.positionsearch2 {
    display: flex;
    justify-content: center;
    align-items: center;
    left: 100%;
    gap: 10%;
}

.position_dashboard_flex {
    /* display: flex; */
    /* justify-content: space-between; */
    /* align-items: center; */

    display: flex;
  /* flex-direction: column; */
  align-items: center;

  flex-wrap: wrap;
  justify-content: flex-start;
  }

.position_dashboard_span span{
    position: relative;
    justify-content: center;
    left: 35%;
    top: 5px;
}

.provider_dashboard_span {
    float: left;
    margin-right: 1%;
}

.calendar_dashboard_span {
    float: left;
}

.provider_calendar_div {
    position: relative;
    /* top: 50px; */
}

.admin_action_style {
    display: inline-block;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    /* position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; */
  /* padding-bottom: 50px; replace 50px with the height of your footer */
  /* overflow-y: scroll; */
}

.preauth_login_button_position {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 500px;
    position: fixed;
}

.selectproviderheader {
    position: absolute;
    top: 55%;
    left: 30%;
    margin-top: 0px auto;
}

.appointmentdateheader {
    display: flex;
    position: absolute;
    top: 55%;
    left: 60%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
}

.calendar {
    display: flex;
    position: absolute;
    top: 140%;
    left: 0%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
}

.providerlist {
    display: flex;
    position: absolute;
    top: 60%;
    left: 30%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
}

.createpatientuppertext {
    color: transparent;
}

.department_label {
    width: 130px;
    padding-right: 10px;
}

.sitebutton {
    padding-right: 5px;
}

.datepickerstyle {
    height: 35px;
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
    width: 80%;
}

.datepickerstylepatientcreate {
    height: 35px;
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
    /* width: 80%; */
}

.searchbarstyle {
    width: 200%;
    border-radius: 30.25rem;

}


.modal-80w {
    width: 80%;
    max-width: none!important;
}

.link {
    color: rgb(19, 125, 246);
    cursor:pointer;
}

.provider_appt_mrn_selection {
    color: blue;
    text-decoration: underline;
    cursor: default;
}

/* .Table tr:nth-of-type(odd) {
    background-color: #f5f5f5;
  } */

  .table-striped tr:nth-of-type(odd) td.patient-oddrow-mrns {
    color: blue !important;
    text-decoration: underline !important;
    cursor: default;
  }

/* .provider-form {
    width: 200%;
} */
/* 
.modal-open {
    background-color: black;
  } */

  .password-input-container-dashboard {
    position: relative;
  }
  
  .password-toggle-dashboard {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;
  }
  
  .merge-container {
    display: grid;
    /* Create 3 equal columns */
    /* grid-template-columns: repeat(3, 1fr);  */
    grid-template-columns: 3fr 1fr 3fr;
    column-gap: 10px; /* Add spacing between columns if needed */
    align-items: center; /* Center align within each column */
  }

  .merge-container2 {
    display: grid;
    /* grid-template-columns: repeat(3, 1fr); */
    grid-template-columns: 3fr 1fr 3fr;
    column-gap: 10px; /* Add spacing between columns if needed */
    /* align-items: center; */
  }
  
  .merge-section {
    text-align: center; /* Center the h1 and component within each column */
  }

  .merge-section2 {
    text-align: left;
  }

  .merge-arrow-icon {
    font-size: 10rem;
  }

  .merge-button-div {
    display: flex;
    justify-content: center;
  }

  .info-container {
    /* display: flex; */
    display: flex;
  /* justify-content: space-between; */
  justify-content: center;
    /* align-items: center; */
  }
  
  .info-title {
    /* margin-right: 5px;  */
    /* Adjust the spacing between h4 and p */

    
  }

  .info-content {
    /* border: 1px solid #000;  */
    /* Example border for the content box */
    /* padding: 5px;
    margin-left: 10px;  */
    /* Adjust the spacing between the boxes */
  }

  .info-title-container {
    border: 1px solid #000; /* Border style for the title container */
    padding: 5px; /* Adjust padding as needed */
  }
  
  .info-content-container {
    border: 1px solid #000; /* Border style for the content container */
    padding: 5px; /* Adjust padding as needed */
  }

  .red-text {
    color: red;
  }