.visit_panel_component_div {
    padding-left: .5%;
}

.visit_panels_main_div {
    /* width: 16.6666%; */
    width: 16.6%;
}

.card_header{
    height: 75px;
}
/* .div_botox_map_panel {
    width: 100%;
    height: 695px;
    overflow-y: scroll;
    overflow-x: hidden;
} */

.div_visit_panel {
    /* max-height: 600px; */
    height: 695px;
    width: 100%;
    /* overflow-y: scroll; */
    overflow-y: auto;
    overflow-x: hidden;
    /* margin-left: -20px;
    margin-right: 50px; */
}

.div_visit_panel_diag_intv {
    height: 695px;
    width: 100%;
    /* overflow-y: scroll; */
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: -12px;
}

.list_panel_div {
    /* padding-left: 10px;
    text-indent: -10px; */
    margin-left: -11px;
    /* font-size: large; */
}



@media screen and (min-width: 575px) and (max-width: 600px) {
    /* CSS rules specific to this screen size range */
    .card_header{
        height: 200px;
    }
  }

  @media screen and (min-width: 601px) and (max-width: 800px) {
    /* CSS rules specific to this screen size range */
    .card_header{
        height: 180px;
    }
  }

@media screen and (min-width: 801px) and (max-width: 899px) {
    /* CSS rules specific to this screen size range */
    .card_header{
        height: 150px;
    }
  }

@media screen and (min-width: 900px) and (max-width: 1500px) {
    /* CSS rules specific to this screen size range */
    .card_header{
        height: 110px;
    }
  }

  /* @media screen and (min-width: 1500px) {

    .card_header{
        height: 110px;
    }
  } */

@media only screen and (max-device-width: 820px) and (orientation: portrait) {
    /* Styles for iPad Mini in portrait mode */
    .card_header{
        height: 135px;
    }
  }
  
  @media only screen and (max-device-width: 768px) and (orientation: portrait) {
    /* Styles for iPad Mini in portrait mode */
    .card_header{
        height: 150px;
    }
  }
  @media only screen and (max-device-width: 1024px) and (orientation: landscape) {
    /* Styles for iPad Mini in landscape mode */
    .card_header{
        height: 95px;
    }
  }

  @media screen and (min-width: 100px) and (max-width: 450px) {
    /* CSS rules specific to this screen size range */
    .card_header{
        height: auto;
    }
    .visit_panels_main_div {
        /* width: 16.6666%; */
        width: 100%;
    }
    .div_visit_panel {
        /* max-height: 600px; */
        /* height: auto; */
        height: auto;
        width: 100%;
        /* overflow-y: scroll; */
        overflow-y: auto;
        overflow-x: hidden;
        /* margin-left: -20px;
        margin-right: 50px; */
    }
    .div_visit_panel_diag_intv {
        height: auto;
        width: 100%;
        /* overflow-y: scroll; */
        overflow-y: auto;
        overflow-x: hidden;
        margin-left: -12px;
    }
  }
  