
.face-instruments-form-check-input.good {
    appearance: auto;
    border-color: rgb(81, 80, 80) !important;

} 
.face-instruments-form-check-input.error {
    appearance: none;
    /* border-color: rgb(81, 80, 80) !important; */
    /* filter: hue-rotate(128deg); */
    width: 13px; 
    height: 13px; 
    border-radius: 50%; 
    border: 1px solid red; /* Border color */ 
    background-color: white;
}

.face-instruments-form-check-input.error:checked {
    appearance: auto;
    filter: hue-rotate(128deg);
}